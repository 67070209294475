import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import cards from '../component/util/cards'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import WorkshopEnrollmentOverview from './adminViews/workshopEnrollmentOverview'

const OverviewPage = (props) => {
  let BlankCard = cards.blankCard
  const navigate = useNavigate()
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const windowLocation = useLocation()

  const handleLogIn = () => {
    let redirectString = `${windowLocation.pathname}${windowLocation.search}`
    navigate(`/login`, {state: { redirectTo: redirectString }})
  }

  const description = () => {
    if (props.description.includes('\n')) {
      return props.description.split('\n').map((line, index) => (
        <Typography
          align='left'
          key={'description-' + index}
          sx={{ marginBottom: '10px' }}
        >
          {line}
        </Typography>
      ))
    } else {
      return <Typography align='left'>{props.description}</Typography>
    }
  }
  description()

  if (props.toggleCustomer == 'Admin') {
    return (
      <>
        <Box
          sx={{
            display: 'grid',
            width: '95%',
            margin: 'auto auto'
          }}
        >
          <Typography
            variant='h3'
            sx={{ textAlign: 'left', padding: '10px 10px' }}
          >
            Camp Enrollment
          </Typography>
          <WorkshopEnrollmentOverview season={props.season} />
          {props.children}
        </Box>
      </>
    )
  } else {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '100%', md: '70% auto' },
          gridGap: { xs: '0px', md: '30px' },
          width: '80%',
          margin: 'auto auto 200px auto'
        }}
      >
        <Box sx={{ textAlign: 'left', gridColumn: isLoggedIn ? '1/3' : '1/2' }}>
          <Typography variant='h2' sx={{ marginBottom: '5px' }}>
            {props.title}
          </Typography>
          <BlankCard>
            {description()}
          </BlankCard>
        </Box>
        {isLoggedIn ? (
          <></>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridColumn: { xs: '1/2', md: '2/3' }
            }}
          >
            <Typography variant='h2' sx={{ margin: '10px 0px' }}>
              Ready to Enroll?
            </Typography>
            <Button
              variant='contained'
              size='large'
              sx={{ fontSize: '20px' }}
              onClick={() => handleLogIn()}
            >
              Log In
            </Button>
          </Box>
        )}
        <Box sx={{ gridColumn: '1 / 3', textAlign: 'left' }}>
          {props.children}
        </Box>
      </Box>
    )
  }
}

export default OverviewPage
