import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    products: [],
    useAltPayment: false,
    altPaymentType: '',
    altPaymentDetails: {},
    note: '',
    amount: null,
    startDate: null,
    dateConfirmed: false
  },
  reducers: {
    addProduct: (state, action) => {
      let tmp = Object.assign(
        { cartID: Math.random(), student: 'default' },
        action.payload
      )
      state.products.push(tmp)
    },
    removeProduct: (state, action) => {
      state.products = state.products.filter(
        (item) => item.cartID !== action.payload.cartID
      )
    },
    assignStudent: (state, action) => {
      state.products = state.products.map((x) =>
        x.cartID === action.payload.product
          ? { ...x, student: action.payload.student }
          : x
      )
    },
    initLocalCart: (state, action) => {
      state.products = action.payload
    },
    clearCart: (state, action) => {
      state.products = []
    },
    setUseAltPayment: (state, action) => {
      state.useAltPayment = action.payload
    },
    setAltPaymentType: (state, action) => {
      state.altPaymentType = action.payload
    },
    setAltPaymentDetails: (state, action) => {
      state.altPaymentDetails = action.payload
    },
    setNote: (state, action) => {
      state.note = action.payload
    },
    setAmount: (state, action) => {
      state.amount = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  addProduct,
  removeProduct,
  assignStudent,
  initLocalCart,
  clearCart,
  setUseAltPayment,
  setAltPaymentType,
  setAltPaymentDetails,
  setNote,
  setAmount
} = cartSlice.actions

export default cartSlice.reducer
