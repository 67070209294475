import React from 'react'
import { Typography, Box, IconButton, Tooltip } from '@mui/material/'
import cards from '../component/util/cards.js'
import LaunchIcon from '@mui/icons-material/Launch'
import { Email } from '@mui/icons-material'

const OrdersStripeView = ({checkoutSessions, handleReceiptDialog }) => {
  const BlankCard = cards.blankCard

  const styles = {
    grid: {
      display: 'inline-grid',
      gridTemplateColumns: `70% 10% 10% 10%`,
      width: '100%',
      borderBottom: '1px solid #e3e3e3',
      borderCollapse: 'separate',
      padding: '10px 0px'
    }
  }
  
  return (
    <React.Fragment>
      <Box sx={{ padding: '0 5%' }}>
        {checkoutSessions.length === 0 ? (
          <Typography>No orders yet</Typography>
        ) : (
          <BlankCard style={{ overflowX: 'auto' }}>
            <Box id='table-header' sx={{ ...styles.grid }}>
              <Typography variant='h6'>Order Date</Typography>
              <Typography variant='h6'>Total</Typography>
              <Typography variant='h6'></Typography>
              <Typography></Typography>
            </Box>
            {checkoutSessions.map((row, index) => {
              return (
                <Box key={index} sx={{ ...styles.grid }}>
                  <Typography>
                    {new Date(row.created * 1000).toDateString()}
                  </Typography>
                  <Typography>${row.amount_total / 100}</Typography>
                  <Tooltip describeChild title='View Stripe Receipt'>
                    <IconButton
                      aria-label='more details link'
                      size='small'
                      onClick={() => window.open(row.receipt_url)}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                  {/* TO DO: hide this from parents but show the rest */}
                    <Tooltip describeChild title='Send Receipt Email'>
                      <IconButton
                        aria-label='send receipt'
                        size='small'
                        onClick={() => handleReceiptDialog(row)}
                      >
                        <Email />
                      </IconButton>
                    </Tooltip>

                </Box>
              )
            })}
          </BlankCard>
        )}
      </Box>
    </React.Fragment>
  )
}

export default OrdersStripeView
