import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {
  DialogTitle,
  DialogContentText,
  Box,
  Typography,
  colors
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputMask from 'react-input-mask'
import ModelHandler from '../models/modelHandler'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Paper } from '@mui/material'
import CONSTANTS from '../constants'

export default function StudentForm({
  handleClose,
  handleChange,
  data,
  handleNext,
  accountInfo,
  fromCreateAccount,
  handleNewUser,
  saveRoboteer,
  backButton,
  skipRoboteer
  //cancelAddRoboteer
}) {
  const [focus, setFocus] = React.useState(null)
  const [error, setError] = React.useState([])
  const [showCheck, setShowCheck] = React.useState(false)
  const [duplicate, setDuplicate] = React.useState(null)
  let hasStudents = useSelector((state) => state.parent.hasStudents)
  let students = useSelector((state) => state.parent.students)

  const styles = {
    helpText: {
      color: '#d32f2f',
      fontSize: 12,
    },
    tempWeb: {}
  }

  const next = () => {
    let errors = ModelHandler.checkStudentData(data, true)
    if (errors.length >= 1) {
      setError(errors)
    } else if (hasStudents) {
      let check = students.filter((existing) => data.fName == existing.info.name.fName)
      if (check.length > 0) {
        setShowCheck(true)
        setDuplicate(check[0])
      } else {
        handleNext()
      }
    } else {
      handleNext()
    }
  }

  const holdRoboteerData = () => {
    let errors = ModelHandler.checkStudentData(data, true)
    if (errors.length >= 1) {
      setError(errors)
    } else if (hasStudents) {
      let check = students.filter((existing) => data.fName == existing.info.name.fName)
      if (check.length > 0) {
        setShowCheck(true)
        setDuplicate(check[0])
      } else {
        saveRoboteer()  
      }
    } else {
      saveRoboteer()  
    }
  }

  const STUDENT_FIELDS = [
    {
      type: 'fName',
      key: 'fName',
      label: 'First Name',
      isRequired: true
    },
    {
      type: 'lName',
      key: 'lName',
      label: 'Last Name',
      isRequired: true
    },
    {
      type: 'mName',
      key: 'mName',
      label: 'Middle Name',
      isRequired: false
    },
    // {
    //   type: 'email',
    //   key: 'email',
    //   label: 'Email',
    //   isRequired: false
    // },
    // {
    //   type: 'password',
    //   key: 'password1',
    //   label: 'Password',
    //   helper: '1 Capital, 1 Number, be 8 characters long and have a special character "!@#$%^_&*"',
    //   isRequired: true
    // },
    {
      type: 'dob',
      key: 'dob',
      label: 'Date of Birth',
      helper: 'MM/DD/YYYY',
      isRequired: true
    }
  ]

  const standardField = (item) => {
    return (
      <TextField
        required={item.isRequired}
        margin='dense'
        id={item.type}
        key={item.type}
        error={error.includes(item.type)}
        label={item.label}
        type={item.type}
        onFocus={() => {
          setFocus(item.key)
        }}
        helperText={focus === item.key ? item.helper || null : null}
        fullWidth
        onChange={(e) => {
          handleChange(item.type, e.target.value)
        }}
        variant='standard'
      />
    )
  }

  const dobField = (item) => {
    return (
      <React.Fragment key={item.key}>
        <InputMask
          mask='99/99/9999'
          onChange={(e) => {
            handleChange(item.type, e.target.value)
          }}
          onFocus={() => {
            setFocus(item.key)
          }}
        >
          {() => (
            <TextField
              required={item.isRequired}
              margin='dense'
              fullWidth
              error={error.includes('tooOld') || error.includes('invalidDate')}
              id={item.type}
              key={item.key}
              helperText={focus === item.key ? item.helper : null}
              label={item.label}
              type={item.type}
              variant='standard'
            />
          )}
        </InputMask>
        <p style={styles.helpText}>{error.includes('tooOld') && 'Children must be under the age of 18'}</p>
      </React.Fragment>
    )
  }


  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const formContent = () => {
    return (
      <>
        <DialogTitle>
          Enter your child's information
          {handleClose ? <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
            : <></>}
        </DialogTitle>
        <DialogContent>
          {STUDENT_FIELDS.map((item) => {
            if (item.type === 'dob') {
              return dobField(item)
            } else {
              return standardField(item)
            }
          })}
          <TextField
            id='filled-multiline-flexible'
            label='Notes:'
            multiline
            maxRows={4}
            onChange={(e) => {
              handleChange('pNotes', e.target.value)
            }}
            variant='filled'
          />
          <FormGroup>
            {/* <FormControlLabel //leave for later
              control={<Checkbox defaultChecked onChange={(e) => { handleChange('addrCheck', e.target.checked) }} />}
              label='Use my address'
            /> */}
            {/* <FormControlLabel
              style={{pointerEvents: 'none'}}
              control={<Checkbox style={{ pointerEvents: 'auto' }} onChange={(e) => { handleChange('vax', e.target.checked) }} />}
              label='Vaccinated'
            /> */}
            {/* <FormControlLabel

              style={{pointerEvents: 'none'}}
              control={<Checkbox style={{ pointerEvents: 'auto' }} onChange={(e) => { handleChange('eula', e.target.checked) }} />}
              label={
                <a target='_blank' href='http://rollingrobots.com/terms'>EULA</a>
              }
            /> */}
            <div
              style={{
                overflowY: 'scroll',
                height: '100px',
                textAlign: 'left',
                border: '1px solid #DDD',
                width: '100%',
                fontSize: '8pt'
              }}
            >
              <p>
                <b>Workshop Release and Waiver</b>
              </p>

              <p>
                This is a Consent, Release of Rights, Hold Harmless and
                Authorization Agreement (Agreement”) between the Rolling Robots,
                Inc., Rolling Robots West LA, LLC. , Rolling Robots Outreach,
                Inc., Rolling Robots STEM partners, and officers, directors,
                employees and Designees of each of same (collectively, “Rolling
                Robots”), and Participant (defined below and referred to as “I”,
                “Me”, “My” and “Participant”). “Designees” includes those
                persons or entities managing, contracting, sponsoring, hosting,
                conducting, evaluating or publicizing (including individuals and
                entities working with Rolling Robots in print, publication,
                television, broadcast or video media). As used in this
                Agreement, “Participant” shall mean any individual, student,
                mentor, teacher, volunteer, or other person or entity involved
                in a Rolling Robots activities, workshops, class, or other event
                (collectively, “Rolling Robots Activity”).
              </p>

              <p>
                In consideration of My participation in one or more Rolling
                Robots activities, I agree to the following: I hereby grant
                Rolling Robots the right to photograph, videotape, or otherwise
                digitally record or collect My name, biography, image, likeness,
                voice, sounds, plus any other information I provide, associated
                with My attendance at and/or participation in any Rolling Robots
                Activity. I further acknowledge that any works made by or for
                Rolling Robots (“Works”) to be “works made for hire,” under
                applicable copyright laws, and to the extent I have or may claim
                any rights in or to such Works, I hereby grant to Rolling Robots
                any and all such rights, including, without limitation, the
                right to use or sublicense these Works in any and all media, now
                known or later discovered or developed, and in all forms and for
                all purposes, including without limitation, advertising and
                other promotions for RollingRobot, without any further
                consideration due to Me or any limitation whatsoever.
              </p>

              <p>
                {' '}
                I recognize that there may be risks associated with attending
                and/or participating in a Rolling Robots Activity, including,
                without limitation, risks inherent in the construction and/or
                operation of robots, as well as in working with electrical
                connections, traveling to and from events, and participating in
                public competitions. These risks include the risk of injury
                (including without limitation, serious bodily harm and even
                death) and property damage. Being fully cognizant of the risks
                in participating in any such event, I hereby fully and willingly
                assume those risks. Except to the extent due to the gross
                negligence or willful misconduct of Rolling Robots, to the
                fullest extent permitted by applicable laws, I HEREBY WAIVE ANY
                CLAIMS OR CAUSES OF ACTION which I may now have or hereafter
                arises against Rolling Robots arising out of or connected to My
                participation in any Rolling Robots Activity and I will
                indemnify and hold harmless Rolling Robots against any and all
                claims against any such entity or person resulting from same.
              </p>

              <p>
                I HEREBY RELEASE Rolling Robots and each of their respective
                successors, assigns, affiliates, licensees and agents from any
                and all claims, demands, liabilities, damages, costs and
                expenses, attorneys' fees, other professional fees and expenses,
                including, without limitation, any claims for compensation,
                defamation, or invasion of privacy, or other infringements or
                violations of personal or property rights of any sort whatsoever
                that I may now or hereafter arise against Rolling Robots arising
                in connection with my participation in any Rolling Robots
                Activity.
              </p>

              <p>
                In the event I should sustain any injuries or illness while
                attending and/or participating in a Rolling Robots Activity, I
                hereby authorize Rolling Robots to administer, or cause to be
                administered, such first aid or other treatment and such
                medications as I may possess as reasonably suggested under the
                circumstances, including, without limitation, treatment by a
                physician or hospital of Rolling Robots choice.
              </p>

              <p>
                This Release shall be binding upon My heirs, personal
                representatives, and Me. THIS RELEASE SHALL BE GOVERNED BY AND
                CONSTRUED UNDER THE LAWS OF THE STATE OF CALIFORNIA, WHICH SHALL
                BE THE VENUE FOR ANY LEGAL ACTION. This Agreement constitutes
                the entire agreement among the parties hereto with respect to
                the subject matter hereof and supersedes any and all previous
                agreements among the parties, whether written or oral, with
                respect to such subject matter.
              </p>

              <p>
                I UNDERSTAND THAT THIS FORM INVOLVES A RELEASE OF LEGAL RIGHTS.
              </p>

              <p>
                I confirm that I have current contact information in my profile
                at rollingrobots.net
              </p>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    handleChange('waiver', e.target.checked)
                  }}
                />
              }
              label={
                <p style={{ fontSize: '12px' }}>
                  I agree to the Terms and Conditions
                </p>
              }
            />
            {error.includes('waiver') ? (
              <p style={styles.helpText}>
                Please accept our Terms and Conditions to create a Roboteer.
              </p>
            ) : (
              <></>
            )}
            {/* <FormControlLabel
              style={{pointerEvents: 'none'}}
              control={<Checkbox style={{ pointerEvents: 'auto' }} onChange={(e) => { handleChange('eula', e.target.checked) }} />}
              label= 'ACCEPT'
            />
            <FormControlLabel
              style={{pointerEvents: 'none', float: 'right'}}
              control={<Checkbox style={{ pointerEvents: 'auto' }} onClick={handleClose} />}
              label='DECLINE'
            /> */}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
            
            {fromCreateAccount? (<>
            <div style={{ display: 'flex', flexDirection:'row', width:'100%' }}>
              <div style={{ alignSelf:'flex-start'}} >
              <Button onClick={() => { backButton() }}> 
                Back
              </Button>
              </div>

              <div style={{ display:'flex', paddingLeft:'54%'}}>
              <Button onClick={() => { skipRoboteer() }}>
                Skip & Create
              </Button>

              <Button /* ADD Roboteers and Create New User */
                style={{backgroundColor: CONSTANTS.RED_HEX}}
                onClick={() => { holdRoboteerData() }}>
                <font style={{color: 'white'}}>Create</font>
              </Button>
              </div>
            </div>
            </>):(<> 
              <Button /* ADD Roboteer to Existing User Account */
                onClick={() => { next() }}>
                Create
              </Button>
            </>)}
        </DialogActions>
      </>
    )
  }

  return (
    <>
      {accountInfo ?
        <Paper sx={{ width: '90%', margin: 'auto' }}>
          {formContent()}
        </Paper> : <Dialog open hideBackdrop={true}>
          {formContent()}
        </Dialog>}
      {showCheck ?
        <Dialog open
          hideBackdrop={true}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Roboteer
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              It appears you already have an existing roboteer with this name. Are you sure you want to create another one?
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Box >
                  <Typography variant='body2'>Existing Roboteer</Typography>
                  <Typography>{duplicate.info.name.fName} {duplicate.info.name.mName} {duplicate.info.name.lName}</Typography>
                  <Typography>{duplicate.info.dob}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>You Entered</Typography>
                  <Typography >{data.fName} {data.mName} {data.lName}</Typography>
                  <Typography>{data.dob}</Typography>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button onClick={() => setShowCheck(false)}>Go back</Button>
            <Button onClick={() => {
              setShowCheck(false)
              setDuplicate(null)
              handleNext()
            }}>Create</Button>
          </DialogActions>
        </Dialog>
        : <></>}
    </>


  )
}
