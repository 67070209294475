import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import OrdersStripeView from '../component/ordersStripeView'
import ParentAPI from '../api/parentApi'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert
} from '@mui/material'
import { sendReceipt } from '../api/admin.js'

const OrdersStripeContainer = () => {
  //TO DO: decide if it's better to get this from the redux store or pass it down from account container?
  const uID = useSelector((state) => state.login.user._id)
  const user = useSelector((state) => state.login.user)
  let selectedUser = useSelector((state) => state.user.selectedUser)

  const [checkoutSessions, setCheckoutSessions] = useState([])
  const [loading, setLoading] = useState(true)
  const [showReceiptSender, setShowReceiptSender] = useState(false)
  const [selectedReceipt, setSelectedReceipt] = useState({})
  const [showReceiptSent, setShowReceiptSent] = useState(false)

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let resp = await ParentAPI.getStripeCharges({
        params: { customerId: selectedUser.paymentMeta.stripe.stripeID }
      })
      if (resp.success) {
        setCheckoutSessions(resp.data)
      }
      setLoading(false)
    }
    if (mounted) {
      handleAsync()
    }
    return () => {
      mounted = false
    }
  }, [selectedUser])

  const handleReceiptDialog = (checkoutSession) => {
    setSelectedReceipt(checkoutSession)
    setShowReceiptSender(true)
  }

  const handleCloseReceiptDialog = () => {
    setSelectedReceipt({})
    setShowReceiptSender(false)
  }
  const handleReceipt = async () => {
    let resp
    if (selectedReceipt.invoice) {
      resp = await sendReceipt({
        uID: uID,
        invoiceID: selectedReceipt.invoice,
        parent: selectedUser,
        adminEmail: user.userName
      })
    } else {
      resp = await sendReceipt({
        uID: uID,
        lineItems: selectedReceipt.line_items.data,
        total: selectedReceipt.amount_total,
        discount: selectedReceipt.total_details.amount_discount,
        metadata: selectedReceipt.metadata.meta,
        parent: selectedUser,
        adminEmail: user.userName
      })
    }
    if (resp.status === 200) {
      setShowReceiptSender(false)
      setShowReceiptSent(true)
    } else {
      alert('Something went wrong.')
    }
  }
  if (loading) {
    return <>Loading...</>
  } else {
    if (checkoutSessions.length == 0) {
      return <>No Orders yet</>
    } else {
      return (
        <>
          <Snackbar
            open={showReceiptSent}
            autoHideDuration={6000}
            onClose={() => setShowReceiptSent(false)}
            message='Receipt Sent'
          >
            <Alert>Receipt Sent</Alert>
          </Snackbar>
          <Dialog
            open={showReceiptSender}
            onClose={() => handleCloseReceiptDialog()}
            closeAfterTransition={false}
          >
            <DialogTitle id='receipt-dialog-title'>
              Send Receipt Email Confirmation
            </DialogTitle>
            <DialogContent>
              Are you sure you want to email a receipt confirmation to this customer?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseReceiptDialog()}>Cancel</Button>
              <Button variant='contained' onClick={() => handleReceipt()}>
                Send Receipt
              </Button>
            </DialogActions>
          </Dialog>
          <OrdersStripeView
            checkoutSessions={checkoutSessions}
            handleReceiptDialog={handleReceiptDialog}
          />
        </>
      )
    }
  }
}

export default OrdersStripeContainer
