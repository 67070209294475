import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, Button } from '@mui/material'
import Section from './section'
import Microlink from '@microlink/react'

const BlogSection = ({ styles }) => {
  const PARAGRAPH = '20px'

  const BLOG_CONTENT = [
    {
      title: 'What to expect from a Rolling Robots VEX Competition Team ',
      description:
        'At Rolling Robots we focus on learning not winning, but we do win a lot.',
      link:'https://www.rr-stempartners.org/post/what-to-expect-from-a-vex-robotics-competition-team-at-rolling-robots'
    },
    {
      title: 'InventTeam 2022-23 - Building The Smart Walker',
      description:
        'Rolling Robots InvenTeam aims to solve a problem in our community',
      link:'https://www.rr-stempartners.org/post/inventteam-2022-23-building-the-smart-walker'
    },
    {
      title: 'TWO DIVISION CHAMPIONS AT 2022 VEX WORLDS',
      description: 'Rolling Robots Competition Teams have reached new heights',
      link:'https://www.rr-stempartners.org/post/two-division-champions-at-2022-vex-worlds'
    },
  ]

  return (
    <Section
      title={'Empowering Future Innovators with Proven Results'}
      sx={{ backgroundColor: styles.lightGrey }}
      capitalCase
    >
      <Box
        sx={{
          width: { xs: '90%', md: '60%' },
          margin: 'auto auto',
          textAlign: 'center'
        }}
      >
        <Typography sx={{ marginBottom: PARAGRAPH }}>
          See how Rolling Robots motivates kids to succeed in the tech world.
          The amazing things our students have done and their success in VEX
          Robotics competitions show how engaging and effective our teaching
          methods are.
        </Typography>
        <Typography sx={{ marginBottom: PARAGRAPH }}>
          You're giving your kid a special chance to join the ranks of
          accomplished young innovators by registering them for one of our
          summer camps.
        </Typography>
        <Typography sx={{ marginBottom: '50px' }}>
          See the inspiring pictures below to see what our gifted kids have
          accomplished and to get an idea of the amazing potential your child
          can achieve with Rolling Robots. We'll inspire the upcoming generation
          of trailblazers together!
        </Typography>

      </Box>
      <Box sx={{ ...styles.box }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '100%',
              md: 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
            },
            gridGap: '30px',
            marginBottom: '50px'
          }}
        >
          {BLOG_CONTENT.map((blogPost) => {
            return (
              <Box key={blogPost.title}>
                <Microlink 
                  url = {blogPost.link} 
                  fetchData
                  size="large"
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </Section>
  )
}

export default BlogSection
