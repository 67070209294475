import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilteredSubscriptions,
  setFilteredActivities
} from '../../../store/subscriptionSlice'
import SecondaryNav from '../../util/secondaryNav'
import SubscriptionForm from './subscriptionForm'
import SubscriptionTable from './subscriptionTable'
import SubscriptionGrids from './subscriptionGrids'
import InviteList from './inviteList'
import Tags from '../tags/tags'
import { useNavigate, useParams } from 'react-router-dom'

const SubscriptionsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const allSubscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )

  //Set user view to save currTab when navigating to a membership's activities
  const view = useSelector((state) => state.subscription.view)

  const permissions = useSelector((state) => state.login.user.permissions[0])
  const [currTab, setCurrTab] = useState(view)
  const allActivities = useSelector((state) => state.subscription.allActivities)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)
  const isEdit = useSelector((state) => state.subscription.edit)
  const locations = useSelector((state) => state.user.locations)
  const [activitiesExist, setActivitiesExist] = useState(true);
  let TABS = [
    {
      label: 'Table View',
      content: (
        <SubscriptionTable
          setCurrTab={setCurrTab}
          location={params.locationID}
          activitiesExist={activitiesExist}
        />
      )
    },
    {
      label: 'Grid View',
      content: <SubscriptionGrids activitiesExist={activitiesExist} />
    },
    {
      label: `${isEdit ? 'Edit Membership' : 'Add Membership'}`,
      content: <SubscriptionForm setCurrTab={setCurrTab} />
    },
    { label: 'View Invites', content: <InviteList /> },
    { label: 'Tags', content: <Tags all={true} /> }
  ]

  if (permissions === 3) {
    //remove Add membership tab for instructors
    TABS.splice(2, 1)
  }

  useEffect(() => {
    if (!params.locationID) {
      navigate('all', { replace: true })
    }
    let filteredSubscriptions = []
    if (defaultRegion._id && defaultRegion.name !== 'All') {
      console.log("Default region found, filtering subscriptions")
      //Get all location IDs of locations that exist within default region, then run first pass of filter on subscriptions
      let regionLocations = locations.filter((loc) => loc.region == defaultRegion._id).map((filteredLoc) => filteredLoc._id)
      filteredSubscriptions = allSubscriptions.filter((sub) => regionLocations.includes(sub.locationID))
    } else { 
      console.log("No default region found, showing all subscriptions")
      //If there is no default region selected and the location parameter is set to all, show all subscriptions
      filteredSubscriptions = allSubscriptions
    } 
    //If a location is selected, run another filter on subscriptions
    if (params.locationID !== 'all') {
      filteredSubscriptions = filteredSubscriptions.filter(
        (obj) => obj.locationID === params.locationID
      )
    }
    dispatch(setFilteredSubscriptions(filteredSubscriptions))

    let filteredActivities = []
    for (const subscription of filteredSubscriptions) {
      let tmp = allActivities.filter(
        (obj) => obj.instance.subscriptionID === subscription._id
      )
      filteredActivities.push(...tmp)
    }

    if(filteredActivities.length == 0){
      setActivitiesExist(false)   //Activities don't exist
    }else{
      setActivitiesExist(true)    //Activities do exist
    }
    dispatch(setFilteredActivities(filteredActivities))
  }, [params, allSubscriptions, defaultRegion])

  return (
    <>
      <SecondaryNav
        title={'Admin Memberships'}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
      />
      <>{TABS[currTab].content}</>
    </>
  )
}

export default SubscriptionsView
