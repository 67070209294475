import InstructorAPI from '../api/instructor'
import { store } from '../store/store'
import { setSelectedUser } from '../store/userSlice'
import { useNavigate } from 'react-router-dom'

export default function useNavToAccountView() {
  const navigate = useNavigate()

  async function handleNavToAccountView(uID, data) {
    //Checks for two different data types to get parentID string
    let parentID = typeof data == 'string' ? data : data.parents[0]
    if (uID) {
      let resp = await InstructorAPI.getUser({
        params: { uID: uID, userID: parentID }
      })
      if (resp.success) {
        store.dispatch(setSelectedUser(resp.data))
      }
      navigate(`/account-view/${resp.data._id}`)
    }
  }
  return handleNavToAccountView
}
