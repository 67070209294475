import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Typography,
  TextField,
  Alert
} from '@mui/material'
import {
  setAltPaymentDetails,
  setAltPaymentType,
  setNote,
  setAmount
} from '../../store/cartSlice'
import { setSelectedPrice } from '../../store/subscriptionSlice'
import cards from '../util/cards'

const AltPaymentSelect = ({ errors, FIELDS, dateError }) => {
  const dispatch = useDispatch()
  const BlankCard = cards.blankCard

  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const altPaymentType = useSelector((state) => state.cart.altPaymentType)
  const altPaymentDetails = useSelector((state) => state.cart.altPaymentDetails)
  const selectedPrice = useSelector((state) => state.subscription.selectedPrice)
  const note = useSelector((state) => state.cart.note)
  const amount = useSelector((state) => state.cart.amount)
  let parent = useSelector((state) => {
    if (
      state.login.user.permissions &&
      state.login?.user?.permissions[0] === 4
    ) {
      return state.user.selectedUser
    } else {
      return state.login.user
    }
  })

  const [priceOptions, setPriceOptions] = useState([
    { id: subscription.priceID, period: 'month', price: subscription.price }
  ])
  const [iterationError, setIterationError] = useState(false)

  //Calculate amount
  useEffect(() => {
    if (
      //if type is check, wait for iterations to increase
      (altPaymentDetails.startDate &&
        altPaymentDetails.endDate &&
        altPaymentDetails.iterations > 0) ||
      // if type os homeschool, wait for end date to be set
      (altPaymentDetails.startDate &&
        altPaymentDetails.endDate &&
        altPaymentType.includes('homeschool'))
    ) {
      let amount = 0
      if (altPaymentType === 'check') {
        let price = Number(selectedPrice?.price)
        //Save price in cents
        amount = price * altPaymentDetails.iterations * 100
      } else {
        let startDate = new Date(altPaymentDetails.startDate)
        let endDate = new Date(altPaymentDetails.endDate)
        let length =
          endDate.getMonth() -
          startDate.getMonth() +
          12 * (endDate.getFullYear() - startDate.getFullYear())
        if (length < 1) {
          length = 1
        }
        let price = Number(selectedPrice.price)
        amount = price * length * 100
      }

      dispatch(setAmount(amount))
    }
  }, [altPaymentDetails, selectedPrice])

  useEffect(() => {
    let tmpPrices = [...priceOptions]
    if (subscription.altPrices?.length > 0) {
      subscription.altPrices.forEach((altPrice) => {
        tmpPrices.push(altPrice)
      })
    }
    setPriceOptions(tmpPrices)
  }, [])

  //Calculate the end date based on start date and iterations
  const setEndDate = (tmpDetails) => {
    let period = 0
    if (selectedPrice.period === 'month') {
      period = 1
    } else if (selectedPrice.period === 'quarter') {
      period = 3
    } else if (selectedPrice.period === '9 months') {
      period = 9
    } else {
      period = 12
    }

    let splitDate = tmpDetails.startDate.split('-')
    let addMonths =
      parseInt(splitDate[1]) + parseInt(tmpDetails.iterations * period)
    let addYears = parseInt(splitDate[0])
    while (addMonths > 12) {
      //convert months to years if more than 12
      addMonths -= 12
      addYears += 1
    }
    addMonths = addMonths.toString()
    addYears = addYears.toString()
    if (addMonths.length < 2) {
      addMonths = '0' + addMonths
    }
    let endDate = `${addYears}-${addMonths}-${splitDate[2]}`
    return endDate
  }

  const handleFieldChange = (item, e) => {
    //Check payments use the number of months to calculate the end date and price
    let tmpDetails = { ...altPaymentDetails }
    if (item.id === 'iterations') {
      //set iteration
      tmpDetails['iterations'] = e.target.value
      //Check to make sure iterations are above 0
      if (e.target.value <= 0) {
        setIterationError(true)
        dispatch(setAmount(null))
      } else {
        setIterationError(false)
        tmpDetails['endDate'] = setEndDate(tmpDetails)
      }
    } else {
      tmpDetails[item.id] = e.target.value
      //Change end date if start date is changed and iterations have already been set
      if (
        item.id !== 'paymentID' &&
        tmpDetails.startDate &&
        tmpDetails.iterations
      ) {
        tmpDetails['endDate'] = setEndDate(tmpDetails)
      }
    }
    dispatch(setAltPaymentDetails(tmpDetails))
  }

  const handlePlanChange = (item, e) => {
    dispatch(setSelectedPrice(e.target.value))
  }

  const handlePaymentType = (e) => {
    if (e.target.value.includes('homeschool')) {
      //Set selected price as default subscription price if homeschool
      dispatch(
        setSelectedPrice({
          id: subscription.priceID,
          period: 'month',
          price: subscription.price
        })
      )
    }
    dispatch(setAltPaymentType(e.target.value))
  }

  const alternatePaymentFields = () => {
    if (altPaymentType) {
      let fieldsType = 'homeschool'
      if (!altPaymentType.includes('homeschool')) {
        fieldsType = 'check'
      }
      return (
        <>
          <Typography variant='h4'>4. Payment Details</Typography>
          <BlankCard>
            {FIELDS[fieldsType].map((item) => (
              <React.Fragment key={item.id}>
                {/**Show alternate payment plan options if present only when
                 * the customer chooses check as the alt payment type.
                 */}
                {item.id === 'plan' ? (
                  <>
                    {subscription.altPrices?.length > 0 ? (
                      <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <InputLabel id='simple-select-label'>
                          Payment Plan
                        </InputLabel>
                        <Select
                          labelId='simple-select-label'
                          id='simple-select'
                          value={altPaymentDetails.plan}
                          
                          label='Payment Plan'
                          onChange={(e) => handlePlanChange(item, e)}
                        >
                          {priceOptions.map((option, index) => {
                            return (
                              <MenuItem
                                value={option}
                              >{`$${option.price} per ${option.period}`}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <TextField
                    sx={{ marginTop: '10px' }}
                    fullWidth
                    error={
                      ((item.id === 'startDate' ||
                        item.id === 'endDate' ||
                        item.id === 'iterations') &&
                        dateError) ||
                      (item.id === 'iterations' && iterationError)
                    }
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    variant='outlined'
                    InputLabelProps={
                      item.type == 'date' ? { shrink: true } : null
                    }
                    onChange={(e) => handleFieldChange(item, e)}
                    disabled={
                      item.id === 'iterations' && !altPaymentDetails.startDate
                    }
                  />
                )}
              </React.Fragment>
            ))}
            {dateError ? (
              <Alert severity='error'>End date must be after start date.</Alert>
            ) : (
              <></>
            )}

            {altPaymentType === 'check' ? (
              <>
                <Typography>Make check payable to Rolling Robots.</Typography>
                {altPaymentDetails.startDate && altPaymentDetails.endDate ? (
                  <Typography>
                    Your membership will run from {altPaymentDetails.startDate}{' '}
                    to {altPaymentDetails.endDate}
                  </Typography>
                ) : (
                  <></>
                )}
                {amount ? (
                  <Typography fontWeight='bold'>
                    Total: ${amount / 100}
                  </Typography>
                ) : (
                  <>
                    {iterationError ? (
                      <Typography color='red'>
                        Iterations cannot be less than 1
                      </Typography>
                    ) : (
                      <Typography>
                        Enter start date and iterations to calculate price.
                      </Typography>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Typography>
                  The PO Number is located in the top right corner of the PO
                  document.
                </Typography>
              </>
            )}
          </BlankCard>
          <Typography variant='h4'>Additional Information</Typography>
          <BlankCard>
            {parent.permissions[0] === 4 ? (
              <Typography>
                Leave an optional note to go along with the transaction.
              </Typography>
            ) : (
              <Typography variant='body2'>
                If you need to add more details to your order, leave a short
                note in the box below.
              </Typography>
            )}

            <TextField
              fullWidth
              placeholder='Note'
              multiline
              minRows={2}
              value={note}
              onChange={(e) => dispatch(setNote(e.target.value))}
            ></TextField>
          </BlankCard>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      <Typography variant={'h4'} sx={{ paddingTop: '10px' }}>
        3. Select Alternate Payment Type
      </Typography>
      <BlankCard>
        <FormControl fullWidth>
          <InputLabel>Select an option</InputLabel>
          <Select
            label='Select an option'
            value={altPaymentType}
            onChange={(e) => handlePaymentType(e)}
          >
            <MenuItem value='homeschool-blue-ridge'>Blue Ridge Academy</MenuItem>
            <MenuItem value='homeschool-suncoast'>Suncoast</MenuItem>
            <MenuItem value='check'>Check</MenuItem>
          </Select>
        </FormControl>
      </BlankCard>
      {alternatePaymentFields()}
    </>
  )
}

export default AltPaymentSelect
