import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getReport, getAllReports, requestReport } from '../../../api/finance'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import LoadingComp from '../../util/loading'

const Report = () => {
  const user = useSelector((state) => state.login.user)
  const [report, setReport] = useState(null)
  const [reportType, setReportType] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [reports, setReports] = useState([])
  const [gettingReports, setGettingReports] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [errorCheck, setErrorCheck] = useState(false)
  const [loop, setLoop] = useState(null)
  const [documentOpen, setDocumentOpen] = useState(false)
  const [showDates, setShowDates] = useState(true)
  const [message, setMessage] = useState(false)
  const [email, setEmail] = useState('')
  const [testStart, setTestStart] = useState(new Date())

  const getReports = async () => {
    setGettingReports(true)
    let resp = await getAllReports({ params: { uID: user._id } })
    if (resp.success) {
      setGettingReports(false)
      setReports(resp.data.reportList.data)
    } else {
      setGettingReports(false)
      alert(resp)
    }
  }

  //Initial fetch of reports
  useEffect(() => {
    async function handleAsync() {
      await getReports()
    }
    handleAsync()
  }, [])

  useEffect(() => {
    setEmail(user.info.contact.email)
  }, [user])

  const timer = () => {
    let difference = (new Date().getTime() - testStart.getTime()) / 1000
    console.log('Time Elapsed: ', difference, ' seconds')
  }
  //handle pending report
  useEffect(() => {
    let checkPending = reports.find((item) => item.status === 'pending')
    if (checkPending && !gettingReports && !loop) {
      setLoop(setInterval(getReports, 5000))
    } else if (checkPending) {
      console.log('Pending: ', checkPending)
      timer()
    }
    return function cleanup() {
      timer()
      clearInterval(loop)
      setLoop(null)
    }
  }, [reports])

  const getDates = (report) => {
    let start = new Date(0)
    start.setUTCSeconds(report.parameters.interval_start)
    let startMonth = start.getUTCMonth()
    startMonth = startMonth + 1

    let end = new Date(0)
    end.setUTCSeconds(report.parameters.interval_end)
    let endMonth = end.getUTCMonth()
    endMonth = endMonth + 1
    return (
      startMonth +
      '/' +
      start.getUTCDate() +
      '/' +
      start.getUTCFullYear() +
      ' - ' +
      endMonth +
      '/' +
      end.getUTCDate() +
      '/' +
      end.getUTCFullYear()
    )
  }

  const handleOpenModal = (report) => {
    setDocumentOpen(true)
    setReport(report)
  }

  const handleClick = async (type) => {
    setReportType(type)
    let dates = getDates(report)
    let datesArr = dates.split('-')
    setDocumentOpen(true)
    let resp = await getReport({
      params: {
        url: report.result.url,
        uID: user._id,
        dates: datesArr,
        type: type,
        email: email
      }
    })
    if (resp.success) {
      setShowSuccess(true)
    } else {
      alert(resp)
    }
  }

  const handleDownloadButton = (report, type) => {
    if (report.status === 'succeeded') {
      return (
        <Typography
          sx={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => {
            handleOpenModal(report)
          }}
        >
          Download
        </Typography>
      )
    } else {
      return 'Loading'
    }
  }

  const handleDateChange = (dateType, dateValue) => {
    if (dateType === 'startDate') {
      setStartDate(dateValue)
    } else {
      setEndDate(dateValue)
    }
  }

  const isToday = (someDate) => {
    const today = new Date()
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    )
  }

  useEffect(() => {
    console.log('Start: ', testStart)
    return () => {}
  }, [testStart])

  const handleReportRequest = async () => {
    let today = new Date()
    if (
      endDate.getTime() > startDate.getTime() &&
      today.getTime() > endDate.getTime()
    ) {
      setErrorCheck(false)

      if (isToday(endDate)) {
        let tmpStart = startDate
        tmpStart.setDate(tmpStart.getDate() - 2)
        setStartDate(tmpStart)

        let tmpEnd = endDate
        tmpEnd.setDate(tmpEnd.getDate() - 2)
        setEndDate(tmpEnd)
        setMessage(true)
      }

      let startEpoch = Math.floor(startDate.getTime() / 1000)
      let endEpoch = Math.floor(endDate.getTime() / 1000)
      let resp = await requestReport({
        uID: user._id,
        startEpoch: startEpoch,
        endEpoch: endEpoch
      })
      if (resp.success) {
        let tmpReports = [...reports]
        tmpReports.unshift(resp.data)
        setReports(tmpReports)
        setShowDates(false)
        setTestStart(new Date())
      } else {
        alert(resp)
      }
    } else {
      setErrorCheck(true)
    }
  }

  const handleDocumentClose = () => {
    setDocumentOpen(false)
    setReport(null)
    setShowSuccess(false)
    setReportType(null)
  }

  const handleDates = () => {
    setShowDates(true)
    setMessage(false)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <div style={{ margin: '0 auto', width: '75%' }}>
      {showDates ? (
        <>
          <Typography
            variant='h4'
            align='left'
            style={{ margin: '20px 0 5px 0' }}
          >
            Request New Report
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={'Start Date'}
                value={startDate}
                onChange={(e) => {
                  handleDateChange('startDate', e)
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth error={errorCheck} />
                )}
              />
            </LocalizationProvider>

            <Typography
              style={{ textAlign: 'center', margin: '5px auto' }}
              variant='h1'
            >
              {' '}
              -{' '}
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={'End Date'}
                value={endDate}
                onChange={(e) => {
                  handleDateChange('endDate', e)
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth error={errorCheck} />
                )}
              />
            </LocalizationProvider>
            <Button
              variant='contained'
              style={{ height: '30px', margin: 'auto 5px' }}
              onClick={handleReportRequest}
            >
              Submit
            </Button>
          </div>

          {errorCheck ? (
            <Typography color='red'>
              There was a problem with the submitted dates. Both dates must be
              in the past and the end date must be after start date
            </Typography>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Typography>
            Stripe is processing the request, this may take several minutes. It's status can be viewed below.
            Once completed, a blue download link will appear.
          </Typography>
          {message ? (
            <Typography>
              Important: The date range has been adjusted because Stripe cannot
              process requests ending in today's date.
            </Typography>
          ) : (
            <></>
          )}
          <Button onClick={handleDates}>Request another report</Button>
        </>
      )}

      <Typography variant='h4' align='left' style={{ margin: '20px 0 5px 0' }}>
        Reports
      </Typography>

      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.id}>
                <TableCell>{getDates(report)}</TableCell>
                <TableCell>{report.status}</TableCell>
                <TableCell>{handleDownloadButton(report)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={documentOpen} onClose={handleDocumentClose}>
        <DialogTitle>
          <Typography variant='h6'>Select Report Type</Typography>
        </DialogTitle>
        <DialogContent>
          {showSuccess ? (
            <Typography>
              The report is processing. This may take several minutes. Once it
              is complete, an automatic email will be sent to {email}.
            </Typography>
          ) : (
            <>
              {reportType ? (
                <div style={{ textAlign: 'center' }}>
                  <LoadingComp />
                  <Typography color='red'>
                    Processing Request. This may take several minutes. Please do
                    not navigate away from the page.
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyConten: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <TextField
                    style={{ marginTop: '10px' }}
                    value={email}
                    onChange={handleEmailChange}
                    label={'Recipient Email'}
                  />
                  <Button onClick={() => handleClick('full')}>
                    Financial Report
                  </Button>
                  <Button onClick={() => handleClick('month')}>
                    Purchases By Month
                  </Button>
                  <Button onClick={() => handleClick('location')}>
                    Purchases By Location
                  </Button>
                </div>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Report
