import React from 'react'
import { Box, Typography } from '@mui/material'
import MailChimpInput from './util/mailChimpInput.js'
import { useNavigate } from 'react-router-dom'
import { ArrowForward } from '@mui/icons-material'

const Footer = (props) => {
  const navigate = useNavigate()
  let styles = props.styles
  const FOOTER_BUTTONS = [
    { text: 'About Us', link: '/about' },
    { text: 'Terms & Conditions', link: '/terms#terms-and-conditions' },
    { text: 'Privacy Policy', link: '/terms#privacy-policy' }
  ]

  const SOCIALS = [
    {
      alt: 'Facebook',
      image: 'Asset_14.png',
      link: 'https://www.facebook.com/rollingrobots/'
    },
    {
      alt: 'Twitter',
      image: 'Asset_13.png',
      link: 'https://twitter.com/rollingrobots'
    },
    {
      alt: 'Instagram',
      image: 'Asset_12.png',
      link: 'https://www.instagram.com/rollingrobots/'
    },
    {
      alt: 'Youtube',
      image: 'Asset_11.png',
      link: 'https://www.youtube.com/user/rollingrobots'
    }
  ]
  const MOVED_LOCATIONS = [
    {
      lineOne: '131 N. Artsakh Ave',
      city: 'Glendale',
      state: 'CA',
      zip: '91206',
      phone: '(888) 762-6808'
    },
    {
      lineOne: '301 E. Colorado Blvd',
      lineTwo: 'Suite 100',
      city: 'Pasadena',
      state: 'CA',
      zip: '91101',
      phone: '(424) 445-6689'
    }
  ]
  const FOOTER_CONTACTS = [
    {
      lineOne: '12 Truman St',
      city: 'Irvine',
      state: 'CA',
      zip: '92620',
      phone: '(888) 762-6808'
    },

    {
      lineOne: '2951 S Sepulveda Blvd',
      city: 'Los Angeles',
      state: 'CA',
      zip: '90064',
      phone: '(310) 474-0198'
    },
    {
      lineOne: '704 Bart Earle Way, #201',
      city: 'Rolling Hills Estates',
      state: 'CA',
      zip: '90274',
      phone: '(424) 206-9466'
    }
  ]

  const ContactFormat = ({contact}) => {
    return (
      <Box sx={{ fontSize: '0.8rem', textAlign: 'left' }}>
        <Typography sx={{ fontSize: '0.8rem' }}>{contact.lineOne}</Typography>
        <Typography sx={{ fontSize: '0.8rem' }}>{contact.lineTwo}</Typography>
        <Typography sx={{ fontSize: '0.8rem' }}>
          {contact.city}, {contact.state} {contact.zip}
        </Typography>
        <Typography
          sx={{
            fontSize: '0.8rem',
            color: styles.red,
            fontWeight: 500
          }}
        >
          {contact.phone}
        </Typography>
      </Box>
    )
  }

  return (
    <Box id='footer' sx={{ minHeight: '10vh', ...styles.footer }}>
      <Box
        sx={{
          ...styles.box,
          height: '100%',
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gridTemplateRows: 'auto auto',
          alignItems: 'center',
          marginTop: '25px',
          marginBottom: '25px'
        }}
      >
        <Box
          sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}
        >
          <Box
            component='img'
            src={require('../images/rolling_robots_logo_small_transparent.png')}
            sx={{ maxWidth: '200px' }}
          />
        </Box>

        <Box
          sx={{
            gridColumn: { xs: '1/2', md: '2/3' },
            gridRow: { xs: '2/3', md: '1/2' },
            justifySelf: { xs: 'start', md: 'end' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }
          }}
        >
          {FOOTER_BUTTONS.map((button) => {
            return (
              <Typography
                key={button.text}
                sx={{
                  padding: { md: '0px 20px' },
                  color: styles.red,
                  fontSize: '0.8rem',
                  fontWeight: 600,
                  textAlign: { xs: 'left', md: 'center' },
                  '&:hover': { cursor: 'pointer' }
                }}
                onClick={() => navigate(button.link)}
              >
                {button.text}
              </Typography>
            )
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gridRow: '2/3',
            gridColumn: '2/3',
            alignSelf: 'start'
          }}
        >
          {SOCIALS.map((social) => {
            return (
              <Box
                key={social.alt}
                component='img'
                alt={social.alt}
                src={require(`../images/fbLandingImages/${social.image}`)}
                onClick={() => window.open(social.link)}
                sx={{
                  width: { xs: '25px', md: '40px' },
                  margin: { xs: '0px 5px', md: '0px 10px' },
                  '&:hover': { cursor: 'pointer' }
                }}
              />
            )
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'grid',
          ...styles.box,
          gridTemplateColumns: { xs: '100%', md: 'auto auto auto' },
          gridGap: '20px',
          marginBottom: '20px'
        }}
      >
        <Box sx={{ textAlign: 'left' }}>
          <Typography fontWeight='bold' sx={{ fontSize: '0.8rem' }}>
            Contact Info
          </Typography>
          <Typography sx={{ fontSize: '0.8rem', marginBottom: '20px' }}>
            For In-person Workshop and Membership
          </Typography>
          <Box sx={{display: 'grid',gridGap: '10px', gridTemplateColumns: 'auto auto 50%'}}>
          <ContactFormat contact={MOVED_LOCATIONS[0]} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px 5px'
              }}
            >
              <Typography>Moved to</Typography>
              <ArrowForward />
            </Box>
            <ContactFormat contact={MOVED_LOCATIONS[1]}/>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gridGap: '10px'
            }}
          >
            {FOOTER_CONTACTS.map((contact, index) => {
              return (
                <React.Fragment key={index}>
                  <ContactFormat contact={contact} />
                </React.Fragment>
              )
            })}
          </Box>
        </Box>
        <Box sx={{ textAlign: 'left' }}>
          <Typography fontWeight='bold' sx={{ fontSize: '0.8rem' }}>
            For General Inquiries
          </Typography>
          <Typography
            sx={{ fontSize: '0.8rem', color: styles.red, fontWeight: 500 }}
          >
            888-ROBOT-08
          </Typography>
          <Typography
            display='inline'
            onClick={() =>
              (window.location = 'mailto:contact@rollingrobots.com')
            }
            sx={{
              fontSize: '0.8rem',
              color: styles.red,
              fontWeight: 500,
              '&:hover': { cursor: 'pointer' }
            }}
          >
            E-mail us
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'left' }}>
          <Typography fontWeight='bold' sx={{ fontSize: '0.8rem' }}>
            Subscribe to our Newsletter
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }}>
            Get all the latest Rolling Robots news and event announcements.
          </Typography>
          <MailChimpInput />
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: '1px solid #000',
          ...styles.box,
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px'
        }}
      >
        <Typography
          sx={{
            padding: '10px 10px',
            fontSize: '0.8rem',
            textAlign: 'center'
          }}
        >
          © Copyright Rolling Robots, Inc.. 2023 All Rights Reserved.{' '}
          <a
            href='https://www.freepik.com/author/freepik/icons/basic-rounded-flat_6?t=f&query=robotics'
            style={{ display: 'block' }}
          >
            Icons by Freepik
          </a>
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
