import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setClasses,
  setMemberships,
  setActivities,
  setCourses
} from '../../store/instructorSlice'
import '../../index.css'
import SecondaryNav from '../../component/util/secondaryNav'
import ClassOverview from '../../component/instructorViews/classOverview'
import { getProductsByInstructor } from '../../api/products'
import { getInstructorSubs } from '../../api/subscription'
import { setSelectedUser } from '../../store/userSlice'
import AccountContainer from '../account'
import AddAssignments from '../../component/instructorViews/addAssignments'

const InstructorLandingPage = () => {
  const [currTab, setCurrTab] = useState(0)
  const uID = useSelector((state) => state.login.user._id)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [showAccountInfo, setShowAccountInfo] = useState(false)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getProductsByInstructor({ uID: uID })
      if (resp.data) {
        dispatch(setClasses(resp.data))
      }
      resp = await getInstructorSubs({ params: { uID: uID } })
      if (resp.success) {
        dispatch(setMemberships(resp.data.subscriptions))
        dispatch(setCourses(resp.data.courses))
        dispatch(setActivities(resp.data.activities))
        setLoading(false)
      }
    }
    handleAsync()
  }, [])

  const handleStudent = (data) => {
    dispatch(setSelectedUser(data.parent))
    setShowAccountInfo(true)
  }

  const back = () => {
    setShowAccountInfo(false)
  }

  const TABS = [
    {
      label: 'Overview',
      content: (
        <ClassOverview
          loading={loading}
          setCurrTab={setCurrTab}
          handleStudent={handleStudent}
        />
      )
    },
    {
      label: 'Add Assignments',
      content: <AddAssignments setCurrTab={setCurrTab} />
    }
  ]

  return (
    <>
      {!showAccountInfo ? (
        <>
          <SecondaryNav
            title={'Dashboard'}
            tabs={TABS}
            currTab={currTab}
            setCurrTab={setCurrTab}
          />
          {TABS[currTab].content}
        </>
      ) : (
        <AccountContainer back={back} isLoading={loading} isInstructor />
      )}
    </>
  )
}

export default InstructorLandingPage
