import React, { useState, useEffect } from 'react'
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody
} from '@mui/material'

const CompetitionsList = (props) => {
  const { loading, teamEventData, selectedTeam, programFilter, currTab } = props
  const [filteredEventData, setFilteredEventData] = useState([])
  const [listData, setListData] = useState([])

  let header = ['Team', 'Date', 'SKU', 'Name']
  useEffect(() => {
    let mounted = true
    if (mounted && teamEventData.length > 0) {
      let list = []
      teamEventData.forEach((obj) => {
        if (obj.events.length > 0) {
          obj.events.forEach((event) => {
            let tmpRow = []
            let teamLink = `https://www.robotevents.com/teams/${obj.team.program.code}/${obj.team.number}`
            tmpRow.push({
              link: teamLink,
              label: obj.team.number,
              programCode: obj.team.program.code
            })
            let date = event?.start.split('T')[0].split('-')
            tmpRow.push(`${date[1]}/${date[2]}/${date[0]}`)
            let eventLink =
              'https://www.robotevents.com/robot-competitions/vex-robotics-competition/' + //Using the VRC URL will redirect to the correct program code
              event?.sku +
              '.html'
            tmpRow.push({ link: eventLink, label: event?.sku })
            tmpRow.push(event?.name)

            list.push(tmpRow)
          })
        }
      })
      setListData(list)
    }
    return () => (mounted = false)
  }, [teamEventData])

  useEffect(() => {
    let mounted = true
    async function handleFilter() {
      let tmpArr = listData.filter((row) => {
        if (row[0].label === selectedTeam || selectedTeam === 'All') {
          if (
            row[0].programCode === programFilter ||
            (programFilter === 'AI' && //AI has two program codes
              (row[0].programCode === 'VAIRC' ||
                row[0].programCode === 'VAIC-HS'))
          ) {
            return true
          }
        } else {
          return false
        }
      })
      setFilteredEventData(tmpArr)
    }
    if (mounted) {
      handleFilter()
    }
    return () => {
      mounted = false
    }
  }, [selectedTeam, programFilter, currTab, loading, listData])

  return (
    <>
      {selectedTeam ? (
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {header.map((item) => (
                  <TableCell key={'header-' + item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEventData.length > 0 ? (
                <>
                  {filteredEventData.map((row, rowIndex) => (
                    <TableRow key={rowIndex + 'row' + row[0].label}>
                      {row.map((item) =>
                        typeof item === 'string' ? (
                          <TableCell key={rowIndex + item}>{item}</TableCell>
                        ) : (
                          <TableCell key={rowIndex + item.label}>
                            <a href={item.link}>{item.label}</a>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan='4' align='center'>
                    No events found for this team.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </>
  )
}

export default CompetitionsList
